<template>
  <div class='buynow'>
      <!-- 头部 -->
    <van-nav-bar title="订单支付"   left-arrow @click-left="onClickLeft"/>
    <!-- 内容 -->
    <div class="content">
         <!-- 卡片 -->
           <div class="card" v-for="(item,index) in list" :key="index">
            <div class="top clearfix" @click="jump(item.id)" >
                <div class="image" v-if="item.picList">
                  <img :src="`${https}${item.picList[0].picPath}`" alt="">
                </div>
                <div class="text">
                    <div class="txt">
                      <p>{{item.productName}}</p>
                      <div class="price">
                          <span class="left">
                            <span>￥</span>{{item.specialPrices == null?item.productPrices:item.specialPrices}}
                          </span>
                        <span class="right">X {{item.productNum}}</span>
                      </div>
                    </div>
                </div>
              </div>
              <div class="discount">
                <p>
                  推荐码：<input type="text" placeholder="选填内容，可以不填写" v-model="value">
                </p>
              </div>
           </div>
           <!-- 支付方式 -->
           <div class="buymethods">
                <h4>支付方式</h4>
                <!-- 微信支付 -->
                <div class="wechat shape" :class="wechat==true?'wechatRed':'wechatBlack'" @click="wechatshow">
                  <img :src="wechat==true?duiRed:dui" alt="">
                  <span :class="wechat==true?'red':'black'">微信支付</span>
                  <img :src="wechat==true?lastRed:last" alt="" >
                </div>

             
                <!-- <div class="alipay shape" :class="alipay==true?'alipayRed':'alipayBlack'" @click="alipayshow">
                  <img :src="alipay==true?buyRed:buy" alt="">
                  <span :class="alipay==true?'red1':'black1'"></span>
                  <img :src="alipay==true?lastRed:last" alt="" >
                </div> -->

                
                <!-- <div class="bank shape" :class="bank==true?'bankRed':'bankBlack'" >
                  <img :src="bank==true?cardRed:card" alt="">
                  <span :class="bank==true?'red2':'black2'"></span>
                  <img :src="bank==true?addRed:add" alt="" >
                </div> -->
           </div>
    </div>

    <!-- 底部结算 -->
    <!-- price是价格 -->
    <van-submit-bar :price="alltotal" button-text="立即支付" @submit="onSubmit" >
      <template #tip>
         请在5分钟内完成支付，如未完成该订单将自动取消
      </template>
    </van-submit-bar>
  
  </div>
</template>

<script>
import Vue from 'vue';
import { Stepper } from 'vant';
import { SubmitBar } from 'vant';

Vue.use(SubmitBar);
Vue.use(Stepper);

import { findMallVoByIds } from "@api/servicemall.js";
import {payment} from "../api/servicemall";
import qs from "qs";
  export default {
    data(){
      return{
          num:'',
          value:'',
          //图片
          dui:'',
          duiRed:'',
          buy:'',
          buyRed:'',
          card:'',
          cardRed:'',
          last:'',
          lastRed:'',
          add:'',
          addRed:'',
          wechat:true,
          alipay:false,
          bank:false,
          id:'',
          list:'',
          alltotal:null,
          https:'https://p.writemall.com/mallFile/product/',
          oid:'',
      }
    },
    created(){
        this.imgSrc()
        this.id = this.$route.query.id
        this.oid = sessionStorage.getItem("oid")
        this.getDetails()
        
    },
    methods:{
       //获取详情
      async getDetails(){
        const data = {
          id:this.oid
        }
        const result = await findMallVoByIds(qs.stringify(data));
        if(result.code === 0 ){
          this.list = result.data
          console.log(this.list)
          this.total()
        }
      },
      //跳转详情
      jump(id){
        this.$router.push({
          name:'details',
          query:{
             id:id
          }
        })
      },
      //增加
      addprice(){
        event.stopPropagation()   //阻止冒泡
      },

      //减少
      recude(){
        event.stopPropagation()   //阻止冒泡
      },

      //合计
      total(){
        let num = []
        let a = null
        this.list.map(item =>{
           if(item.specialPrices==null){
             num.push(item.productPrices*item.productNum)
           }else{
             num.push(item.specialPrices*item.productNum)
           }
        })
       for(var i =0;i<num.length;i++){
         a += num[i]
         this.alltotal = a*100
       }
      },
      //微信
      wechatshow(){
         this.wechat = !this.wechat
         if(this.wechat){
           this.alipay = false
           this.bank = false
         }
      },
    
      //立即支付
      async onSubmit(){
        if(this.wechat){
          let list = JSON.parse(localStorage.getItem("profile"))
          window.location.href = `https://p.writemall.com/order2/wxH5WebProductOrderCallBack?userid=${list.userId}&oid=${this.oid}&orderType=1&platform=1`
        }else{
          this.$toast("请选择支付方式！")
        }
      },
      //路径转换
      imgSrc () {
        let duisrc = 'buynow-dui.png'
        let duiRedsrc = 'buynow-dui-red.png'
        this.dui = require('../assets/image/' + duisrc)
        this.duiRed = require('../assets/image/' + duiRedsrc)

        let buysrc = 'buynow-buy.png'
        let buyRedsrc = 'buynow-buy-red.png'
        this.buy = require('../assets/image/' + buysrc)
        this.buyRed = require('../assets/image/' + buyRedsrc)

        let cardsrc = 'buynow-card.png'
        let cardRedsrc  = 'buynow-card-red.png'
        this.card = require('../assets/image/' + cardsrc)
        this.cardRed = require('../assets/image/' + cardRedsrc)

        let lastsrc = 'buynow-true.png'
        let lastRedsrc = 'buynow-true-red.png'
        this.last = require('../assets/image/' + lastsrc)
        this.lastRed = require('../assets/image/' + lastRedsrc)
        
        let addsrc = 'buynow-add.png'
        let addRedsrc = 'buynow-add-red.png'
        this.add = require('../assets/image/' + addsrc)
        this.addRed = require('../assets/image/' + addRedsrc)
      },
        //  返回
      onClickLeft() {
         this.$router.go(-1)
      },
    }
  }
</script>

<style lang="scss" scoped>
.buynow{
    font-family: PingFang SC;
    ::v-deep .van-nav-bar__text{
      color: #333333;
      font-size: 15px;
      font-weight: 500;
    }
    ::v-deep .van-nav-bar .van-icon{
      color: #333333;
    }
    ::v-deep .van-nav-bar__title{
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    ::v-deep .van-nav-bar__content{
      height: 57px;
    }
    .content{
      width: 100%;
      height: 610px;
      background: #f4f4f4;
      .card{
        width: 354px;
        height: 167px;
        margin-left: 11px;
        transform: translateY(10px);
        background: #fff;
        border-radius: 8px;
        .image{
          float: left;
          width: 92.5px;
          height: 94px;
          transform: translateY(13px);
          margin-left: 7px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          img{
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
        }
        .text{
          float: left;
          width:246px;
          height: 94px;
          transform: translateY(13px);
          .txt{
            margin-left: 12px;
            p:first-of-type{
              font-size: 14px;
              font-weight: bold;
              color: #333333;
            }
            p:nth-of-type(2){
              margin-top: 24px;
              font-size: 12px;
              font-weight: 500;
              color: #999999;
            }
            .price{
              color: #EB441E;
              font-size: 16px;
              font-weight: 500;
              margin-top: 10px;
              .left>span{
                font-size: 12px;
              }
              .right{
                float: right;
                color: #999999;
              }
            }
          }
        }
         .discount{
                font-size: 13px;
                font-weight: 500;
                color: #333333;
                margin-top: 35px;
                margin-left: 15px;
                input{
                  margin-left: 10px;
                }
            }
      }
      .buymethods{
          width: 354px;
          height: 125px;
          margin-left: 11px;
          background: #fff;
          border-radius: 8px;
          margin-top: 20px;
          h4{
            font-size: 15px;
            font-weight: bold;
            color: #333333;
            padding-top: 20px;
            margin-left: 15px;
          }
          .shape{
            width: 324px;
            height: 53px;
            border-radius: 26px;
            margin-top: 15px;
            margin-left: 15px;
            position: relative;
            img:first-of-type{
              width: 21px;
              height:20px;
              position: absolute;
              left: 22px;
              top: 50%;
              transform: translateY(-50%);
            }
            span{
              font-size: 14px;
              font-weight: bold;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 60px;
            }
            .red{
              color: #EB451F;
            }
            .black{
              color: #666666;
            }
             .red1{
              color: #EB451F;
            }
            .black1{
              color: #666666;
            }
             .red2{
              color: #EB451F;
            }
            .black2{
              color: #666666;
            }
            img:last-of-type{
              width: 15px;
              height: 15px;
              position: absolute;
              right: 23px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          .wechatRed{
             border: 2px solid #EB451F;
          }
           .wechatBlack{
             border: 2px solid #E0E0E0;
          }
          .alipayRed{
             border: 2px solid #EB451F;
          }
           .alipayBlack{
             border: 2px solid #E0E0E0;
          }
          .bankRed{
             border: 2px solid #EB451F;
          }
           .bankBlack{
             border: 2px solid #E0E0E0;
          }
      }
    }
    ::v-deep .van-submit-bar__text{
      text-align: left;
    }
    ::v-deep .van-submit-bar__tip{
      text-align: center;
    }
    ::v-deep .van-button__text{
      font-size: 14px;
      font-weight: 500;
    }
}
.clearfix:after {
  clear: both;
  content: '';
  display: block;
}
</style>